<template>
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 19"
    data-comp="i-arrow-forward"
  >
    <path
      stroke="currentColor"
      stroke-width="1.5"
      d="M11.291.791 20 9.5l-8.709 8.709m8.21-8.709H0"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowForward"
};
</script>
