<template>
  <button
    :class="[
      'nav-trigger',
      {
        '--open': isNavOpen,
        '--no-bg-color': themeBgColor,
      },
    ]"
  >
    <span class="ssr-only">{{ !isNavOpen ? "Open menu" : "Close menu" }}</span>
    <span class="nav-trigger__icon"></span>
  </button>
</template>

<script setup>
import { useThemeColor } from "@/composables/useThemeColor.js";
const { themeBgColor } = useThemeColor();

defineProps({
  isNavOpen: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.nav-trigger {
  position: relative;
  padding: 10px;
  cursor: pointer;
  width: clampPx(mobile-portrait-medium, desktop-small, 40px, 45px);
  height: clampPx(mobile-portrait-medium, desktop-small, 40px, 45px);
  border-radius: 99px;
  @include transition(background-color 0.6s var(--ease-in-out-quart));

  &:not(.--open) {
    background-color: var(--color-yellow);
  }
  &.--no-bg-color {
    background-color: transparent;
  }

  &__icon {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: -1px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      background-color: currentColor;
      width: 100%;
      height: 2px;
      transform-origin: center center;
      transition: transform 0.6s var(--ease-in-out-quart), background-color 0.6s var(--ease-in-out-quart);
      transition-delay: 0.3s;
      .--open & {
        transition-delay: 0s;
      }
    }
    &::before {
      transform: translateY(5px);
      .--open & {
        transform: rotate(45deg);
      }
    }
    &::after {
      transform: translateY(-5px);
      .--open & {
        transform: rotate(135deg);
      }
    }
  }
}
</style>
