<template>
  <div :class="['quote', { 'quote--sticky': quoteSticky }]" data-comp="a-quote">
    <blockquote :class="quoteClass">
      {{ blok.text }}
    </blockquote>
    <div class="quote__line" v-if="!blok.showQoutes"></div>
    <div class="quote__box" v-else>
      <div class="quote__line quote__line--small"></div>

      <i-quote v-if="blok.showQoutes" class="quote__svg" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({})
  }
});
const quoteLength = props.blok.text.length;
const quoteClass = ref("h3");
const quoteSticky = ref(true);
if (quoteLength > 80) {
  quoteClass.value = "h4";
  quoteSticky.value = false;
}
</script>
<style lang="scss" scoped>
.quote {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  &__box {
    display: flex;
    flex-direction: column;
  }

  &__line {
    position: relative;
    content: "";
    width: 1px;
    margin-top: 30px;
    height: 100px;
    background-color: var(--color-txt-secondary);

    &--small {
      margin-top: 0;
      align-self: flex-end;
      height: 50px;
      right: 37px;
      margin-bottom: 30px;
    }
  }

  &__svg {
    align-self: flex-end;
  }
}
</style>
