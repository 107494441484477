<script setup>
import { ref, onMounted } from "vue";

import { intersect } from "./scrollerHelper.js";
const props = defineProps({
  setCssVar: {
    type: String,
    default: "--scroller-progress",
  },
  once: {
    type: Boolean,
    default: false,
  },
  setProgress: {
    type: Boolean,
    default: true,
  },
  offset: {
    type: Number,
    default: 0.5,
  },
  height: {
    type: String,
    default: "auto",
  },
  distance: {
    type: Number,
    default: 1,
  },
});

const scrollerRef = ref();

onMounted(() => {
  intersect(scrollerRef.value, props.offset, props.setProgress, props.distance, props.setCssVar, props.once);
});
</script>

<template>
  <div class="scroller" ref="scrollerRef">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.scroller {
  height: v-bind("height");
}
</style>
