import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import App from "./App.vue";
import router from "./router/index.js";
const app = createApp(App);

// Register storyblok plugin
app.use(StoryblokVue, {
  accessToken: import.meta.env.VITE_STORYBLOK_TOKEN,
  apiOptions: {
    cache: { type: "memory" },
  },
  use: [apiPlugin],
  bridge: import.meta.env.VITE_IS_EDITOR === "true",
});

// Register rich text renderer
import SbRichtext from "./plugins/sb-richtext/index.js";
app.use(SbRichtext);

// Register globally
Object.entries({
  ...import.meta.glob("./components/01-atoms/*.vue", { eager: true }),
  ...import.meta.glob("./components/02-molecules/*.vue", { eager: true }),
  ...import.meta.glob("./components/03-organisms/*.vue", { eager: true }),
  ...import.meta.glob("./components/04-layouts/*.vue", { eager: true }),
  ...import.meta.glob("./components/07-icons/*.vue", { eager: true }),
  ...import.meta.glob("./components/08-helpers/*.vue", { eager: true }),
}).forEach(([path, definition]) => {
  const componentName = path
    .split("/")
    .pop()
    .replace(/\.\w+$/, "");
  app.component(componentName, definition.default);
});

app.use(createHead(App));
app.use(router);
app.mount("#app");
