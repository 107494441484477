<template>
  <vue-vimeo-player
    loading="lazy"
    class="h-vimeo-player"
    ref="vimeoPlayer"
    :video-id="videoId.toString()"
    :options="{
      title: videoTitle,
      color: 'fff8bd',
      byline: false,
      portrait: false,
      autoplay: false,
      loop: false,
      playsinline: true,
    }"
    @ready="onReady"
    @ended="onEnded"
  />
</template>

<script setup>
import { ref } from "vue";
import { vueVimeoPlayer } from "vue-vimeo-player";

defineProps({
  videoId: {
    type: Number,
    required: true,
  },
  videoTitle: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["loaded", "play", "pause", "ended"]);

var vimeoPlayer = ref(null);

const onReady = (_playerInstance) => (vimeoPlayer.value.player = _playerInstance);
const onEnded = () => emit("ended");

const play = () => vimeoPlayer.value.play();
const pause = () => vimeoPlayer.value.pause();
const seekTo = (time) => vimeoPlayer.value.player.setCurrentTime(time);

defineExpose({ play, pause, seekTo });
</script>

<style lang="scss" scoped>
.h-vimeo-player {
  position: relative;
  width: 100%;
  padding: 56.25% 0 0 0;
  :deep(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
