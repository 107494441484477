<template>
  <div
    :class="{ 'in-view': targetIsVisible }"
    ref="target"
    data-comp="h-in-view"
  >
    <slot></slot>
  </div>
</template>

<script setup>
//https://vueuse.org/core/useIntersectionObserver/#usage

import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";
const props = defineProps({
  rootMargin: {
    type: String,
    default: "0% 0% -300px 0%",
  },
  threshold: {
    type: Number,
    default: 0.5,
  },
});
const emit = defineEmits(["inView"]);
const target = ref(null);
const targetIsVisible = ref(false);

useIntersectionObserver(
  target,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting) {
      targetIsVisible.value = true;
      emit("inView");
    }
  },
  {
    rootMargin: props.rootMargin,
    threshold: props.threshold,
  }
);
</script>
