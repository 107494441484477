<template>
  <svg
    viewBox="0 0 82 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="logo-we-are"
    data-comp="a-logo-bg"
  >
    <path
      fill="currentColor"
      d="M36.877.793h7.56l35.918 38.702H69.72L40.42 9.238v30.257h-7.204L3.79 8.18v31.315H.248V.793h7.56l29.07 31.4V.793ZM77.846 49.611h3.661v38.82H73.83L40.387 56.925V88.43h-3.543v-38.82h7.56l33.442 31.506V49.611ZM40.42 98.805h-3.543v33.639h1.535l-28.12-33.64H.247l32.612 38.82h7.56v-38.82Z"
    />
  </svg>
</template>

<script setup></script>
