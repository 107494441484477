<template>
  <div
    :class="[
      'section',
      bgColor,
      // { 'section--no-border': noBorder }
    ]"
    ref="el"
    data-comp="l-section"
  >
    <h-in-view :threshold="0" rootMargin="-50% 0% -50% 0%">
      <div class="section__inner">
        <slot />
      </div>
    </h-in-view>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useResizeObserver } from "@vueuse/core";
defineProps({
  bgColor: {
    type: String,
    default: "",
  },
  // noBorder: {
  //   type: Boolean,
  //   default: true,
  // },
});

const el = ref(null);
const containSize = ref(null);
useResizeObserver(el, (entries) => {
  const entry = entries[0];
  const { width, height } = entry.contentRect;
  containSize.value = Math.round(width) + "px " + Math.round(height) + "px";
});
</script>

<style lang="scss" scoped>
.section {
  z-index: 1;
  position: relative;

  // border-top: pxToRem(15) solid var(--color-bg-primary);
  // &--no-border {
  //   border-top: none;
  // }
  // @include breakpoint(tablet-landscape, min) {
  //   &--no-border {
  //     border-top: none;
  //   }
  // }

  &__inner {
    content-visibility: auto;
    contain-intrinsic-width: v-bind("containSize");
    .in-view & {
      content-visibility: visible;
    }
    .section:nth-child(1) & {
      content-visibility: visible;
    }
  }
}
.bg-primary {
  background-color: var(--color-bg-primary);
  color: var(--color-txt-primary);
}

.bg-secondary {
  background-color: var(--color-bg-secondary);
  color: var(--color-txt-secondary);
}

.bg-tertiary {
  background-color: var(--color-bg-tertiary);
  color: var(--color-txt-tertiary);
}

.bg-quaternary {
  background-color: var(--color-bg-quaternary);
  color: var(--color-txt-quaternary);
}
</style>
