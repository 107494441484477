<template>
  <nav :class="['navigation', animation]" :key="navigation._uid" data-comp="g-navigation">
    <div class="navigation__logo">
      <router-link to="/" :tabindex="animation === 'animate-in' ? 0 : undefined" class="navigation__logo-link">
        <span class="ssr-only">Go to frontpage</span>
        <a-logo />
      </router-link>
    </div>
    <g-navigation-trigger class="navigation__trigger" :is-nav-open="isNavOpen" @click="toggleNav" tabindex="0" />
    <template v-if="initiatedNavOverlay">
      <Suspense>
        <g-navigation-overlay-async :is-nav-open="isNavOpen" @close-nav="toggleNav" @mounted="onOverlayMounted" />
      </Suspense>
    </template>
  </nav>
</template>

<script setup>
import GNavigationTrigger from "@/components/00-global/g-navigation-trigger.vue";
import { ref, watch, onMounted, defineAsyncComponent, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useScroll, useScrollLock } from "@vueuse/core";
import { useGlobals } from "@/composables/useGlobals.js";
import { useLogoAnimation } from "@/composables/useLogoAnimation.js";
import { useThemeColor } from "@/composables/useThemeColor.js";
import $colors from "@/assets/scripts/colors.js";
const { themeBgColorNav, themeTextColorNav, themeTextColorLogo } = useThemeColor();
// import { useScrollDirection } from "@/composables/useScrollDirection.js";

const GNavigationOverlayAsync = defineAsyncComponent(() => import("@/components/00-global/g-navigation-overlay.vue"));

const { navigation } = useGlobals();
const { animation, isNavOpen } = useLogoAnimation();
// const { direction } = useScrollDirection();

const bodyEl = ref(null);
const mobile = ref(null);
useScroll(mobile);

const isScrollLocked = useScrollLock(bodyEl);
const initiatedNavOverlay = ref(false);

const toggleNav = () => {
  if (!initiatedNavOverlay.value) {
    initiatedNavOverlay.value = true;
    // ... then wait for overlayMounted before toggling
  } else {
    toggle();
  }
};

const onOverlayMounted = () => {
  setTimeout(() => {
    toggleNav();
  }, 10);
};

const toggle = () => {
  isNavOpen.value = !isNavOpen.value;
  if (isNavOpen.value) {
    // opening nav
    isScrollLocked.value = true;
    themeBgColorNav.value = $colors.colorPurple;
    themeTextColorNav.value = $colors.colorCream;
  } else {
    // closing nav
    isScrollLocked.value = false;
    themeBgColorNav.value = null;
    themeTextColorNav.value = null;
  }
};

const route = useRoute();
watch(
  () => route.path,
  () => {
    isScrollLocked.value = false;
    isNavOpen.value = false;
  }
);

onMounted(() => {
  bodyEl.value = document.querySelector("body");
});
</script>

<style lang="scss">
$logo-duration: 0.8;
$header-animation-in: #{$logo-duration}s 0.4s ease forwards;
$header-animation-out: #{$logo-duration * 0.4}s ease forwards;

.navigation {
  --text-color: v-bind(themeTextColorLogo);

  position: fixed;
  backface-visibility: hidden;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width-page);
  padding: calc(var(--container-spacing-y) * 0.65) var(--container-spacing-x);
  height: var(--nav-height-expanded);
  transition: transform 0.6s 0.2s ease-in-out;
  transform: translateY(0%);
  &.--down {
    transform: translateY(-100%);
  }

  &__logo {
    position: relative;
    z-index: 10;
    color: var(--text-color);
    transition: color 0.6s 0.2s ease-in-out, opacity 0.2s;
    .animate-in & {
      opacity: 1;
    }
    .animate-out & {
      opacity: 0;
    }
  }

  &__trigger {
    color: var(--text-color);
    transition: color 0.6s 0.2s ease-in-out;
    z-index: 1;
  }

  // transform: translateY(-#{pxToRem(75)});
  // animation: navIn 0.7s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
</style>
