<template>
  <l-section class="related">
    <l-container>
      <a-header tag="h2" classes="h4" :displayFont="true" class="related__header">You may also like</a-header>
      <div class="related__list">
        <h-slug
          class="related__case"
          v-for="_case in cases"
          :key="_case.full_slug"
          :to="{ linktype: 'story', cached_url: `/${_case.full_slug}` }"
        >
          <div class="related__case-image-wrap">
            <h-picture
              class="related__case-image"
              :source="_case.image.filename"
              ratio="1/1"
              :alt="_case.image.alt"
              sizes="(min-width: 1000px) 33vw, (min-width: 600px) 33vw, calc(100vw)"
              loading="lazy"
            />
          </div>
          <a-header tag="h3" classes="h6" class="related__case-title fw-bold">
            {{ _case.title }}
          </a-header>
        </h-slug>
      </div>
    </l-container>
  </l-section>
</template>

<script setup>
import { ref, onMounted, onActivated } from "vue";
import { useIndexedDb } from "@/composables/useIndexedDb.js";

const props = defineProps({
  fullSlug: {
    type: String,
    default: "",
    required: true,
  },
  type: {
    type: String,
    default: "",
    validator: (value) => ["photography", "video"].includes(value),
  },
});

const { dbGetAllByIndex } = useIndexedDb();
const cases = ref([]);

onMounted(() => findRelatedCases());
onActivated(() => findRelatedCases());

// skipFn will skip the case if this function returns true
const skipFn = (_case) => _case.full_slug === props.fullSlug;

const findRelatedCases = async () => {
  // get 3 least visited cases
  let relatedCases = await dbGetAllByIndex("cases", "last_visited", "asc", 4, skipFn); // get 4 cases
  cases.value = relatedCases.sort(() => Math.random() - 0.5).slice(0, 3); // random order + select only the first 3
};
</script>

<style lang="scss" scoped>
.related {
  background-color: var(--color-bg-secondary);
  color: var(--color-txt-primary);
  &__header {
    color: var(--color-txt-secondary);
    text-align: center;
    margin-bottom: 2rem;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    @include breakpoint(tablet-landscape, max) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
  &__case {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;

    @media (hover: hover) {
      &:hover {
        .related__case-image {
          transform: scale(1.05);
        }
      }
    }
  }
  &__case-image-wrap {
    overflow: hidden;
  }
  &__case-image {
    transition: transform 0.5s var(--ease-in-out-cubic);
  }
  &__case-title {
    margin-top: 1rem;
    // text-align: center;
  }
}
</style>
