import { computed, ref } from "vue";
import $colors from "@/assets/scripts/colors.js";

const themeBgColor = ref("");
const themeTextColor = ref("");

// to overwrite the default theme color
const themeBgColorNav = ref("");
const themeTextColorNav = ref("");

const themeTextColorLogo = computed(() => {
  if (themeTextColorNav.value) return themeTextColorNav.value;
  if (themeTextColor.value) return themeTextColor.value;
  return $colors.colorDark;
});

export function useThemeColor() {
  return {
    themeBgColor,
    themeTextColor,
    themeBgColorNav,
    themeTextColorNav,
    themeTextColorLogo,
  };
}
