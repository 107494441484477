<template>
  <div
    :class="['hero-line', { 'first--load': firstLoad }]"
    data-comp="a-hero-lines"
  >
    <svg fill="none" viewBox="0 0 1679 551" class="hero-line__svg">
      <g stroke-width="2" clip-path="url(#a)">
        <path d="M.473 374.119 326.47 549.118" />
        <path d="m.733 198.319 325.536 350.355" />
        <path d="m326-168.001.997 717.999" />
        <path d="M994.732-167.318 327.264 549.034" />
        <path d="M995.271 190.285 327.507 549.111" />
        <path d="m994.473 190.119 668.997 359" />
        <path d="m994.732-168.682 668.998 718" />
        <path d="M1664-36v586" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h1679v551H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script setup>
import { useGlobals } from "@/composables/useGlobals.js";
const { firstLoad } = useGlobals();

const time = 6000 * 0.8;
if (firstLoad.value) {
  setTimeout(() => {
    firstLoad.value = false;
  }, time);
}
</script>

<style lang="scss" scoped>
// remember to sync the time in setup script & @/01-atoms/hpage-intro-animation.vue
$line-logo-animation-time: 0.6;
$line-logo-animation-delay-time: $line-logo-animation-time * 5.3;

.hero-line {
  position: relative;
  width: 100%;
  height: 50vh;
  margin-top: pxToRem(32);
  margin-bottom: pxToRem(30);
  display: flex;
  justify-content: center;
  align-items: center;

  &__svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 103%;
    min-width: 1440px;
  }

  & path {
    stroke: var(--color-txt-secondary);
    animation: dash 0.4s #{$line-logo-animation-delay-time}s linear forwards;
    opacity: 1;
  }
}

.first--load {
  & path {
    stroke: var(--color-txt-secondary);
    animation: dash 0.4s #{$line-logo-animation-delay-time}s linear forwards;
    opacity: 0;

    &:nth-child(1) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.08}s;
    }

    &:nth-child(2) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.16}s;
    }

    &:nth-child(3) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.24}s;
    }

    &:nth-child(4) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.32}s;
    }

    &:nth-child(5) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.4}s;
    }

    &:nth-child(6) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.48}s;
    }

    &:nth-child(7) {
      animation-delay: #{($line-logo-animation-delay-time) + 0.56}s;
    }
  }
}

@keyframes dash {
  to {
    opacity: 1;
  }
}
</style>
