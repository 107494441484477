<template>
  <l-section
    ref="heroRef"
    :bg-color="'bg-secondary'"
    :no-border="true"
    data-comp="l-hero"
    class="hero"
    v-editable="blok"
  >
    <div class="hero__inner">
      <l-grid class="hero__wrap">
        <l-grid-item :spanDesktop="6" :spanTablet="6" :spanMobile="12" class="hero__text-wrap">
          <l-container class="hero__content">
            <l-flex justifyContent="space-between">
              <l-flex gap="xsmall">
                <a-header tag="h1" classes="h5">{{ blok.job_title }}</a-header>
                <sb-richtext :text="blok.intro_text" class="hero__content-intro" />
              </l-flex>
              <l-flex gap="large" :flexGrow="0">
                <a-header tag="h2" classes="h2">
                  <a-header-display
                    :text="blok.name"
                    :positions="['top', 'bottom']"
                    :outlines="[false, true]"
                    color="purple"
                  />
                </a-header>
                <div class="hero__content-contact-wrap">
                  <l-flex direction="horizontal" gap="xsmall" class="hero__content-contact" :flexGrow="0">
                    <h-slug :to="{ email: shared.email }" class="link-u">{{ shared.email }}</h-slug>
                    <h-slug :to="{ url: `tel:${shared.phone}` }" class="link-u">{{ shared.phone }}</h-slug>
                  </l-flex>
                  <l-flex direction="horizontal" gap="xsmall" class="hero__content-contact" :flexGrow="0">
                    <h-slug
                      v-for="soMe in shared.socialMedia"
                      :key="soMe._uid"
                      :to="soMe.link"
                      target="_blank"
                      class="link-u"
                      v-editable="soMe"
                    >
                      {{ soMe.label }}
                    </h-slug>
                  </l-flex>
                </div>
              </l-flex>
            </l-flex>
          </l-container>
        </l-grid-item>
        <l-grid-item :spanDesktop="6" :spanTablet="6" :spanMobile="12" class="hero__carousel-wrap">
          <m-image-carousel :images="blok.media" />
        </l-grid-item>
      </l-grid>
      <div class="hero__scroll-cta">
        <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 10L12 14L8 10" stroke="#181818"></path>
        </svg>
        <span>Scroll to explore my work</span>
      </div>
    </div>
    <!-- <pre>{{blok}}</pre> -->
  </l-section>
</template>

<script setup>
import { ref } from "vue";
import { useGlobals } from "@/composables/useGlobals.js";
import { useIntersectionObserver } from "@vueuse/core";
import { intersectionThresholds } from "@/assets/scripts/intersection-helpers.js";
import { useLogoAnimation } from "@/composables/useLogoAnimation.js";

const { shared } = useGlobals();
const { heroVisible } = useLogoAnimation();
const heroRef = ref(null);

const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
});

useIntersectionObserver(
  heroRef,
  ([{ boundingClientRect }], observerElement) => {
    if (boundingClientRect.bottom > 0) {
      heroVisible.value = true;
    } else {
      heroVisible.value = false;
    }
  },
  { threshold: intersectionThresholds() }
);
</script>

<style lang="scss" scoped>
.hero {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh-min, 1vh) * 100);
  width: 100%;
  // padding: 0 var(--container-spacing-x);
  position: relative;

  @include breakpoint(tablet-landscape, min) {
  }

  &__inner {
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh-min, 1vh) * 100);
    display: grid;
    grid-template-rows: 1fr auto;
  }

  &__content {
    height: 100%;
  }
  &__content-intro {
    color: var(--color-txt-primary);
  }
  &__content-contact {
    color: var(--color-txt-primary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
  }

  &__wrap {
    // min-height: 100vh;
    // min-height: fill-available;
    row-gap: 0;
    @include breakpoint(mobile-landscape, max) {
      // mobile
      // grid-template-rows: auto auto;
      grid-template-rows: 1fr 1fr;
      grid-template-rows: 1fr minmax(220px, auto);
    }
  }
  &__text-wrap {
    @include breakpoint(mobile-landscape, max) {
      // mobile
      grid-row-start: 1;
    }
    @include breakpoint(mobile-landscape, min) {
      // tablet
    }
    @include breakpoint(tablet-landscape, min) {
      // desktop
    }
  }
  &__carousel-wrap {
    @include breakpoint(mobile-landscape, max) {
      // mobile
      grid-row-start: 2;
    }
    @include breakpoint(mobile-landscape, min) {
      // tablet
    }
    @include breakpoint(tablet-landscape, min) {
      // desktop
    }
  }

  &__scroll-cta {
    font-size: var(--font-size-xsm);
    line-height: var(--line-height-xsm);
    background-color: white;
    color: var(--color-dark);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: pxToRem(10) 1.5rem pxToRem(10);

    .icon {
      animation: scroll-cta 2.5s infinite;
    }
  }
}

@keyframes scroll-cta {
  0% {
    transform: translateY(0%);
  }

  10% {
    transform: translateY(7.5%);
  }

  20% {
    transform: translateY(0%);
  }

  30% {
    transform: translateY(7.5%);
  }

  40% {
    transform: translateY(0%);
  }
}
</style>
