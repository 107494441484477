<template>
  <div class="logo" data-comp="a-logo">
    <span class="logo__text">Isabella Capobianco</span>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.logo {
  position: relative;
  opacity: 0;
  .loaded & {
    opacity: 1;
  }

  &__text {
    font-family: var(--font-family-display);
    font-size: 18px;
    font-display: optional;
    transform-origin: left center;
    position: relative;
  }
}
</style>
