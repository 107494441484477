export function intersect(el, offset, setProgress, distance, setCssVar, once) {
  let config = {
    elementHeight: 0,
    offsetTop: 0,
    pageY: 0,
    pageH: document.documentElement.clientHeight,
  }

  const setOffsetTop = () => {
    config.offsetTop = el.offsetTop
  }
  const updateScrollPosition = () => {
    const vh = config.pageH
    const vOffset = vh * offset

    const elRec = el.getBoundingClientRect()
    const elH = elRec.height
    const elY = elRec.y

    let scrollAmount
    let scrollY
    let elPos

    scrollAmount = 2 * elH
    if (vOffset > 0) {
      scrollAmount = 2 * elH + (vh - elH)
    }
    scrollY = vOffset - elY
    elPos = (((2 - offset) / scrollAmount) * scrollY) / distance

    if (elPos > 1) {
      elPos = 1
    } else if (elPos < 0) {
      elPos = 0
    }
    el.style.setProperty(setCssVar, elPos)
  }

  let ticking = false

  const handleScroll = (e) => {
    if (!ticking) {
      window.requestAnimationFrame(function () {
        updateScrollPosition()
        ticking = false
      })

      ticking = true
    }
  }

  const obCallback = (payload) => {
    if (payload[0].isIntersecting) {
      el.classList.add('in-view')

      if (setProgress) {
        window.addEventListener('resize', setOffsetTop)
        window.addEventListener('scroll', handleScroll)
      }
    } else {
      if (!once) {
        el.classList.remove('in-view')
      }

      if (setProgress) {
        window.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', setOffsetTop)
      }
    }
  }

  const ob = new IntersectionObserver(obCallback, {
    rootMargin: `0px 0px ${offset * 100 - 100}%`,
  })
  ob.observe(el)
  setTimeout(() => {
    handleScroll()
  }, 500)

  // onBeforeUnmount(() => {
  //   ob.unobserve(el);
  //   window.removeEventListener("scroll", handleScroll);
  //   window.removeEventListener("resize", setOffsetTop);
  //   el.style.setProperty(setCssVar, 0);
  //   el.classList.remove('in-view');
  // });
  // onDeactivated(() => {
  //   ob.unobserve(el);
  //   window.removeEventListener("scroll", handleScroll);
  //   window.removeEventListener("resize", setOffsetTop);
  //   el.style.setProperty(setCssVar, 0);
  //   el.classList.remove('in-view');
  // });
}
