<template>
  <div class="grid" data-comp="l-grid">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: var(--grid-gap-y) var(--grid-gap-x);
}
</style>
