<template>
  <div class="display-text">
    <span
      v-for="word in words"
      :key="word.text"
      class="display-text__word"
      :class="[`--pos-${word.position}`, `--outline-${word.outline}`, `--color-${color}`]"
    >
      <div class="display-text__base">
        <span>
          {{ word.text }}
        </span>
      </div>
      <div class="display-text__effect">
        <span>
          {{ word.text }}
        </span>
      </div>
    </span>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
    default: "Hello World",
  },
  color: {
    type: String,
    default: "purple",
    validator: (value) => {
      return ["purple", "cream"].includes(value);
    },
  },
  positions: {
    type: Array,
    validator: (value) => {
      // only 'top' and 'bottom' are allowed
      return value.every((val) => ["top", "bottom"].includes(val));
    },
  },
  outlines: {
    type: Array,
  },
});

const defaultPos = "top";
const defaultOutline = false;

const words = computed(() => {
  if (!props.text) {
    return [];
  }
  let words = props.text.split(" ");
  words = words.map((word, index) => {
    return {
      text: word,
      position: props.positions[index] || defaultPos,
      outline: props.outlines[index] || defaultOutline,
    };
  });
  return words;
});
</script>

<style lang="scss" scoped>
.display-text {
  position: relative;
  margin-bottom: 3%;

  &__word {
    position: relative;
    display: block;
    font-family: var(--font-family-display);
    line-height: 1;

    &.--outline-true {
      color: transparent;
      -webkit-text-stroke-width: 1.5px;

      &.--color-purple {
        -webkit-text-stroke-color: var(--color-purple);
      }

      &.--color-cream {
        -webkit-text-stroke-color: var(--color-cream);
      }
    }
  }

  &__base {
  }

  &__effect {
    position: absolute;
    top: 100%;
    overflow: hidden;
    // outline: .1rem solid blue;

    height: 20%;
    line-height: 0;
    width: 100%;

    .--pos-top & {
      top: 0%;
    }

    > span {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      .--pos-top & {
        transform: translateY(136%);
      }

      .--pos-bottom & {
        transform: translateY(-145%);
      }
    }
  }

  &.--pos-top {
    .display-text__effect {
      top: 0;
      transform: translateY(-40%);

      > span {
        transform: translateY(5%);
      }
    }
  }
}
</style>
