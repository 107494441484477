<template>
  <a class="skip-to-content ssr-only" href="#content">Skip to main content</a>
  <g-navigation />
  <div id="content" :class="{ '--editor': isEditor }">
    <router-view v-slot="{ Component, route }">
      <Transition name="router">
        <template v-if="Component" :key="route.path">
          <keep-alive>
            <Suspense :key="route.path">
              <component :is="Component" :slug="route.path" :key="route.path"></component>
              <template #fallback>
                <h-loading />
              </template>
            </Suspense>
          </keep-alive>
        </template>
      </Transition>
    </router-view>
  </div>
  <!-- <div class="frame-page"></div> -->
</template>

<script setup>
import { onMounted, onBeforeUnmount } from "vue";
import GNavigation from "@/components/00-global/g-navigation.vue";

const isEditor = import.meta.env.VITE_IS_EDITOR === "true";

let vhMin = undefined;
let vhInit = undefined;

const setAppHeight = (e) => {
  const window = e?.target;
  const vh = window.innerHeight * 0.01;

  // save initial value
  if (!vhInit) vhInit = vh;

  // save new value if it's smaller; otherwise keep old
  if (!vhMin) vhMin = vh;
  else if (vh < vhMin) vhMin = vh;

  // Set values in custom css property to the root
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vh-min", `${vhMin}px`);
  document.documentElement.style.setProperty("--vh-init", `${vhInit}px`);
};

onMounted(() => {
  setTimeout(() => {
    // used by presite to tell when the page is ready for ssg (static site generation)
    window.snapshot && window.snapshot();
  }, 400);
  setTimeout(() => {
    // preventing flicker on first page load (when the page is hydrating)
    document.querySelector("body").classList.add("loaded");
  }, 500);

  window.addEventListener("resize", setAppHeight);
  window.dispatchEvent(new Event("resize"));
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", setAppHeight);
});
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.skip-to-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

#content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.loaded #content {
  opacity: 1;
}

// .frame-page {
//   @include breakpoint(tablet-landscape, min) {
//     @media (hover: hover) {
//       position: fixed;
//       content: "";
//       width: 100%;
//       height: 100%;
//       pointer-events: none;
//       border: pxToRem(10) solid var(--color-bg-primary);
//       z-index: 100;
//       transform: scale(1.3);
//       animation: frame 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
//       backface-visibility: hidden;
//       left: 0;
//     }
//   }
// }

// @keyframes frame {
//   to {
//     transform: scale(1);
//   }
// }

.router-enter-active {
  width: 100%;
}

.router-enter-active {
  transition: opacity 0.5s 0.4s ease;
}

.router-leave-active {
  transition: opacity 0.3s ease;
}

.router-enter-from {
  width: 100%;
}

.router-leave-active {
  position: absolute;
  width: 100%;
}

.router-enter-from,
.router-leave-to {
  opacity: 0;
}
</style>
