import { ref, computed } from "vue";

const isNavOpen = ref(false);
const heroVisible = ref(false);
const footerVisible = ref(false);

export function useLogoAnimation() {
  const animation = computed(() => {
    if (isNavOpen.value) return "animate-in";
    else if (heroVisible.value) return "animate-out";
    else if (footerVisible.value) return "animate-out";
    else return "animate-in";
  });

  return {
    animation,
    isNavOpen,
    heroVisible,
    footerVisible,
  };
}
