<template>
  <img
    class="v-lazy-image"
    v-if="loading === 'eager'"
    :srcset="webpSrc"
    :sizes="sizes"
    :src="lazySrc"
    :alt="alt"
    :width="100"
    :height="Math.round(imgRatio * 100)"
    data-comp="h-picture"
  />
  <v-lazy-image
    data-comp="h-picture"
    v-else
    :srcset="webpSrc"
    :sizes="sizes"
    :src="lazySrc"
    :src-placeholder="lazySrc"
    :intersection-options="{ rootMargin: '1000px 1000px 1000px 1000px' }"
    @intersect="onIntersect"
    @load="onLoad"
    :alt="alt"
    width="100"
    :height="Math.round(imgRatio * 100)"
  />
</template>

<script setup>
import { computed } from "vue";
import VLazyImage from "v-lazy-image";

const emit = defineEmits(["onIntersect"]);
const props = defineProps({
  source: {
    type: String,
    default: "",
  },
  ratio: {
    type: String,
    default: "",
  },
  alt: {
    type: String,
    default: "",
  },
  sizes: {
    type: String,
    default: "",
  },
  loading: {
    type: String,
    default: "",
  },
});

const crops = [300, 600, 960, 1024, 1280, 1440, 1920];
const splitPath = props.source.replace("https://a.storyblok.com/", "");
const imgPath = computed(() => props.source.replace("https://a.storyblok.com/", ""));
const imgService = computed(() => "https://img2.storyblok.com/");
// const imgOptions = computed(() => "/smart/filters:format(webp)/"); // with smart crop which crops the image based on the people focal point
const imgOptions = computed(() => "/filters:format(webp)/");
const imgOrgSize = computed(() => {
  const getWH = splitPath.split("/")[2];
  const w = Number(getWH.split("x")[0]);
  const h = Number(getWH.split("x")[1]);
  return h / w;
});

// const aspect = inject("aspect", null);
// if (aspect) aspect.value = imgOrgSize.value;

const imgRatio = computed(() => {
  let ratio = 9 / 16;
  if (props.ratio === "org") {
    ratio = imgOrgSize.value;
  } else if (props.ratio !== "fill") {
    const w = Number(props.ratio.split("/")[0]);
    const h = Number(props.ratio.split("/")[1]);
    ratio = h / w;
  }
  return ratio;
});

const webpSrc = computed(() => {
  return crops
    .map((w) => {
      const h = Math.round(imgRatio.value * w);
      return `${imgService.value}${w}x${h}${imgOptions.value}${imgPath.value} ${w}w`;
    })
    .join(",");
});

const lazySrc = computed(() => {
  return `${imgService.value}${100}x${Math.round(imgRatio.value * 100)}${imgOptions.value}${imgPath.value}`;
});

const onIntersect = () => {
  emit("onIntersect");
};
const onLoad = () => {
  // loaded event has been fixed with a patch to the original package
  // console.log("image loaded", props.source);
};
</script>
<style lang="scss" scoped>
.v-lazy-image {
  width: 100%;
  height: auto;
  display: block;
}
</style>
