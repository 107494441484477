<template>
  <div class="logo" data-comp="m-animate-logo">
    <svg fill="none" class="logo__svg" title="MNV">
      <path
        class="m"
        fill="currentColor"
        d="M21.022.893h4.199l19.95 21.496h-5.908L22.99 5.584v16.805h-4.002L2.645 4.996V22.39H.677V.893h4.2l16.145 17.44V.893Z"
      />
      <path
        class="n"
        fill="currentColor"
        d="M43.779 28.009h2.034v21.56h-4.265L22.973 32.072V49.57h-1.968V28.009h4.2l18.574 17.499v-17.5Z"
      />
      <path
        class="v"
        fill="currentColor"
        d="M22.99 55.333h-1.968v18.684h.852L6.255 55.333H.677l18.114 21.561h4.199V55.333Z"
      />
    </svg>
    <span class="logo__text">marius nacht ventures </span>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
$logo-duration: 0.8;
$header-animation-in: #{$logo-duration}s 0.4s ease forwards;
$header-animation-out: #{$logo-duration * 0.4}s ease forwards;
$logo-animation: #{$logo-duration + 0.4}s ease forwards;

.header {
  height: 130px;
  display: flex;
  align-items: center;
  padding: 0 100px;
  background-color: #f6faff;
  transform: translate3d(0, 0, 0, 0);
  &.animate-in {
    animation: header--height--in $header-animation-in;
  }
  &.animate-out {
    animation: header--height--out $header-animation-out;
  }
}
.logo {
  display: flex;
  align-items: center;
  position: relative;
  &__svg {
    position: relative;
    width: 116px;
    height: 77px;
    transform: translate3d(0, 0, 0, 0);
    // .animate-in & {
    //   animation: logo--ratio--in $logo-animation;
    // }
    // .animate-out & {
    //   animation: logo--ratio--out $logo-animation;
    // }
    & .m {
      transform: translate3D(0, 0, 0) scale(1);
      .animate-in & {
        animation: logo--m--in $logo-animation;
      }
      .animate-out & {
        animation: logo--m--out $logo-animation;
      }
    }
    & .n {
      transform: translate3D(0, 0, 0) scale(1);
      .animate-in & {
        animation: logo--n--in $logo-animation;
      }
      .animate-out & {
        animation: logo--n--out $logo-animation;
      }
    }
    & .v {
      transform: translate3D(0, 0, 0) scale(1);
      .animate-in & {
        animation: logo--v--in $logo-animation;
      }
      .animate-out & {
        animation: logo--v--out $logo-animation;
      }
    }
  }
  &__text {
    font-family: "Roboto Flex", sans-serif;
    color: var(--color-txt-primary);
    font-size: 18px;
    line-height: 20px;
    width: 120px;
    margin-left: 14px;
    transform-origin: left center;
    position: relative;
    transform: scale(1) translate3d(-70px, -3px, 0);
    transition: transform #{$logo-duration * 0.7}s #{$logo-duration * 0.7}s ease;
    .animate-in & {
      transition: transform #{$logo-duration * 0.7}s ease;
      transform: scale(0.7) translate3d(-5px, -1px, 0);
    }
  }
}
</style>
