import { createWebHistory, createRouter } from "vue-router";
import Pages from "@/views/Pages.vue";

export const routes = [
  {
    path: "/:catchAll(.*)",
    component: Pages,
    meta: {
      transitionName: "fade",
      pageType: "page",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 400);
      });
    }
  },
});

export default router;
