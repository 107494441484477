<template>
  <svg
    fill="none"
    viewBox="0 0 10 11"
    width="1em"
    height="1em"
    data-comp="i-arrow-down"
  >
    <path
      stroke="currentColor"
      d="M8.85 6.035 4.937 9.901 1.072 5.987m3.866 3.69L4.992.97"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowDown"
};
</script>
