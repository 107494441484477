<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24" data-comp="i-arrow-outward">
    <path
      fill="currentColor"
      d="M6 6v2h8.59L5 17.59L6.41 19L16 9.41V18h2V6z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IconArrowOutward"
};
</script>
