<template>
  <div
    :class="['image', { loaded }]"
    data-comp="a-image"
    v-if="blok.image && blok.image.filename"
  >
    <h-picture
      :source="blok.image.filename"
      :ratio="blok.ratio"
      :alt="blok.image.filename"
      sizes="(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)"
      loading="lazy"
      @onIntersect="imageIntersecting"
    />
    <small v-if="blok.text" class="fz-small">
      {{ blok.text }}
    </small>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
});
const loaded = ref(false);
const imageIntersecting = () => {
  loaded.value = true;
};
</script>
<style lang="scss">
.image {
  opacity: 0;
  @include transition($transition-opacity-media);
  &.loaded {
    opacity: 1;
  }
  & small {
    display: block;
    text-align: right;
    margin-top: 5px;
  }
}
</style>
