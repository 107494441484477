<template>
  <div class="comp-show-reel" v-editable="blok">
    <h-scroller :offset="1" :distance="0.66">
      <div class="comp-show-reel__wrap">
        <div class="comp-show-reel__content" @click="onClickDesktop">
          <div class="comp-show-reel__text">
            <p
              class="comp-show-reel__pre-label"
              v-if="blok.pre_label"
              v-html="blok.pre_label.replace(/\n/g, '<br />')"
            ></p>
            <a-header tag="h2" classes="h1">
              <a-header-display :text="blok.label" :positions="['top', 'bottom']" :outlines="[false, true]" />
            </a-header>
          </div>
          <h-video-lazy
            class="comp-show-reel__video"
            :video="blok.video_teaser"
            :poster="blok.video_teaser_poster"
            :cover="true"
          ></h-video-lazy>
          <p class="comp-show-reel__caption" v-if="blok.caption" v-html="blok.caption.replace(/\n/g, '<br />')"></p>
        </div>
      </div>
    </h-scroller>
    <Transition name="fade">
      <div class="comp-show-reel__fullscreen" v-show="isFullscreen" @click.prevent="stopReel">
        <template v-if="initiatedVimeoPlayer">
          <Suspense>
            <template #default>
              <h-vimeo-player-async
                ref="$video"
                :video-id="blok.vimeo_video.vimeo_data.id"
                :video-title="blok.vimeo_video.vimeo_data.title"
                @ended="onVideoEnded"
              />
            </template>
            <template #fallback>
              <h-loading :inverted="true" />
            </template>
          </Suspense>
        </template>
        <button class="comp-show-reel__fullscreen-close">Close</button>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineAsyncComponent, nextTick, watch } from "vue";
import { useIsTouchDevice } from "@/composables/useIsTouchDevice.js";
import { useScroll, useScrollLock } from "@vueuse/core";

const hVimeoPlayerAsync = defineAsyncComponent(() => import("@/components/08-helpers/h-vimeo-player.vue"));

defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
});

const initiatedVimeoPlayer = ref(false);

const bodyEl = ref(null);
const isScrollLocked = useScrollLock(bodyEl);

const isFullscreen = ref(false);
const $video = ref(null);
const isTouchDevice = useIsTouchDevice();

function onVideoEnded() {
  $video.value.seekTo(0);
  isFullscreen.value = false;
  // this.$cursor.setState(this.$cursor.states.PLAY_REEL);
}
function onClickDesktop() {
  if (!isTouchDevice.value) {
    playReel();
  }
}
function playReel() {
  initiatedVimeoPlayer.value = true;
  isScrollLocked.value = true;
  isFullscreen.value = true;

  // since we're lazy loading the video player, we need to wait for it to be loaded before we can play it
  if ($video.value) {
    $video.value.play();
  } else {
    const unwatch = watch($video, (video) => {
      if (video) {
        video.play();
        unwatch();
      }
    });
  }
  // this.$cursor.setState(this.$cursor.states.CLOSE);
}
function stopReel() {
  isScrollLocked.value = false;
  isFullscreen.value = false;
  $video.value.pause();
  $video.value.seekTo(0);
  // this.$cursor.setState(this.$cursor.states.PLAY_REEL);
}

onMounted(() => {
  bodyEl.value = document.querySelector("body");
});
</script>

<style lang="scss" scoped>
.comp-show-reel {
  &__wrap {
    height: 200vh;
    cursor: pointer;
  }

  &__content {
    position: sticky;
    top: 0;
    height: 100vh;
    background-color: var(--color-yellow);
    overflow: hidden;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(calc(0.3 + var(--scroller-progress) * 0.7));
    opacity: 0.5;
  }
  &__poster-image,
  &__poster-video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin: 0;
    font-family: var(--ff-display);
  }

  :deep(.display-text) {
    transition: transform 0.15s ease-out;

    > :nth-child(1) {
      color: var(--color-purple);
      transform: translateY(-10%) translateX(calc(50% * var(--scroller-progress) - 50%));
    }

    > :nth-child(2) {
      color: transparent;
      transform: translateY(10%) translateX(calc(-50% * var(--scroller-progress) + 50%));

      // -webkit-text-stroke-width: 0.0001vw;
      // -webkit-text-stroke-color: var(--color-purple);
    }
  }

  &__pre-label {
    color: var(--color-purple);
    font-size: 1rem;
    text-align: center;
    padding: 0 2rem;
    width: 100%;
    max-width: 60rem;
  }

  &__caption {
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-purple);
    font-size: 1rem;
    text-align: center;
    padding: 0 clampRem(1rem, 2rem);
    width: 100%;
    max-width: 60rem;
  }

  &__fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background-color: var(--color-dark);
    color: var(--color-cream);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    video {
      width: 100%;
    }
  }

  &__fullscreen-close {
    background-color: var(--color-bg-secondary);
    color: var(--color-txt-primary);
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 99px;
    padding: 0.3rem 0.5rem;
  }
}

// TRANSITIONS
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
