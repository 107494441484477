<template>
  <svg width="1em" height="1em" viewBox="0 0 20 20" data-comp="i-linkedin">
    <path
      fill="currentColor"
      d="M2.5 18h3V6.9h-3V18zM4 2c-1 0-1.8.8-1.8 1.8S3 5.6 4 5.6s1.8-.8 1.8-1.8S5 2 4 2zm6.6 6.6V6.9h-3V18h3v-5.7c0-3.2 4.1-3.4 4.1 0V18h3v-6.8c0-5.4-5.7-5.2-7.1-2.6z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IconLinkedin"
};
</script>
