<template>
  <time :datetime="date" data-comp="a-date">{{ outputDate }}</time>
</template>
<script setup>
// https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios
const props = defineProps({
  date: {
    type: String,
    default: ""
  }
});
const options = { year: "numeric", month: "short", day: "numeric" };
const event = new Date(props.date.replace(/-/g, "/"));
const outputDate = event.toLocaleDateString("en-US", options);
</script>
