<template>
  <l-section class="teaser" v-editable="blok" :class="[`--align-${blok.image_align}`, { '--hover': hover }]">
    <l-container>
      <l-flex
        :direction="blok.image_align === 'right' ? 'horizontal' : 'horizontal-reverse'"
        gap="large"
        class="teaser__wrap"
        wrap="nowrap"
      >
        <l-flex class="teaser__text" justify-content="flex-end">
          <h-slug :to="blok.link" class="teaser__link" @mouseenter="hoverTxt = true" @mouseleave="hoverTxt = false">
            <a-header tag="h2" classes="h3">
              <span class="teaser__title-text">{{ blok.title }}</span>
            </a-header>
            <a-header tag="span" classes="h5" class="teaser__subtitle">
              <span class="teaser__subtitle-text">{{ substring.first }}&nbsp;</span>
              <span class="nowrap">
                <span class="teaser__subtitle-text">
                  {{ substring.last }}
                </span>
                <svg
                  width="23"
                  height="8"
                  viewBox="0 0 23 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="teaser__link-arrow"
                >
                  <path
                    d="M22.3536 4.35355C22.5488 4.15829 22.5488 3.84171 22.3536 3.64645L19.1716 0.464466C18.9763 0.269204 18.6597 0.269204 18.4645 0.464466C18.2692 0.659728 18.2692 0.976311 18.4645 1.17157L21.2929 4L18.4645 6.82843C18.2692 7.02369 18.2692 7.34027 18.4645 7.53553C18.6597 7.7308 18.9763 7.7308 19.1716 7.53553L22.3536 4.35355ZM0 4.5H22V3.5H0V4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </a-header>
          </h-slug>
        </l-flex>
        <div class="teaser__image">
          <h-slug
            :to="blok.link"
            :aria-label="blok.title"
            @mouseenter="hoverImg = true"
            @mouseleave="hoverImg = false"
            :tabindex="-1"
          >
            <h-picture
              :source="blok.image.filename"
              ratio="org"
              :alt="blok.image.alt"
              sizes="(min-width: 1000px) 50vw, (min-width: 600px) 100vw, calc(100vw)"
              loading="lazy"
            />
          </h-slug>
        </div>
      </l-flex>
    </l-container>
    <!-- <pre>aspect:{{ aspect }}</pre>
    <pre>flexBasisImg: {{ flexBasisImg }}</pre> -->
    <!-- <pre>{{ blok }}</pre> -->
  </l-section>
</template>

<script setup>
import { provide, ref, computed } from "vue";
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
});

const substring = computed(() => {
  const arr = props.blok.subtitle.split(" ");
  if (arr.length <= 0) return { first: "", last: "" };
  let last = arr.pop();
  let first = arr.join(" ");
  return { first, last };
});

// HOVER
const hoverImg = ref(false);
const hoverTxt = ref(false);

const hover = computed(() => hoverImg.value || hoverTxt.value);
const hoverDelayImg = computed(() => (hoverImg.value ? "0s" : "0.2s"));
const hoverDelayTxt = computed(() => (hoverTxt.value ? "0s" : "0.2s"));

const transformImg = computed(() => {
  return props.blok.image_align === "right"
    ? "rotate(2.5deg) translate(0.5rem, 0.5rem)"
    : "rotate(-2.5deg) translate(-0.5rem, -0.5rem)";
});
const transformTxt = computed(() => {
  return props.blok.image_align === "right"
    ? "rotate(-2.5deg) translate(-0.5rem, -0.5rem)"
    : "rotate(2.5deg) translate(0.5rem, 0.5rem)";
});

// IMAGE
// const aspect = ref(null);
// provide("aspect", aspect);

// const flexBasisImg = computed(() => (aspect.value < 1 ? "60%" : `${1 / aspect.value}%`));
</script>

<style lang="scss" scoped>
.teaser {
  --transform-img: v-bind(transformImg);
  --transform-txt: v-bind(transformTxt);
  --hover-dalay-img: v-bind(hoverDelayImg);
  --hover-dalay-txt: v-bind(hoverDelayTxt);
  --aspect: v-bind(aspect);

  &.--align-right {
    @include breakpoint(tablet-landscape, min) {
      margin-right: 7vw;
    }
  }
  &.--align-left {
    @include breakpoint(tablet-landscape, min) {
      margin-left: 7vw;
    }
  }

  &__wrap {
    .--align-center & {
      justify-content: center;
    }
    @include breakpoint(tablet-landscape, max) {
      flex-direction: column-reverse;
    }
  }

  &__text {
    // flex: 1 1 auto;
    @include breakpoint(tablet-landscape, min) {
      padding-bottom: 10%;
    }
    .--align-right & {
      text-align: right;
    }

    .--align-center & {
      flex-grow: 0;
      @include breakpoint(tablet-landscape, min) {
        flex-basis: 20rem;
      }
    }
  }

  &__image {
    // flex: 1 1 60%;
    // @include breakpoint(tablet-landscape, min) {
    // }

    img {
      max-height: 70vh;
      object-fit: contain;
      transition: 0.8s var(--ease-in-out-cubic);
      .--hover & {
        transition-delay: var(--hover-dalay-img);
        transform: var(--transform-img);
      }
    }
  }

  &__link {
    display: inline-flex;
    flex-direction: column;
    gap: 0.25rem;
    transition: all 0.8s var(--ease-in-out-cubic);
    .--hover & {
      transition-delay: var(--hover-dalay-txt);
      transform: var(--transform-txt);
    }
  }

  &__title-text {
    font-weight: var(--font-weight-bold);
  }

  &__subtitle-text {
    position: relative;
    // color: var(--color-txt-secondary);
    &:after {
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 0.1rem;
      background-color: var(--color-txt-primary);
      // background-color: var(--color-txt-secondary);
    }

    &:after {
      display: none;
    }
  }

  &__link-arrow {
    margin-left: 0.75rem;
    // color: var(--color-txt-secondary);
  }

  .nowrap {
    white-space: nowrap;
  }
}
</style>
