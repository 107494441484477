<template>
  <div class="carousel">
    <div
      class="carousel__slide"
      v-for="(image, index) in images"
      :key="image.id"
      :class="{ '--inactive': index !== activeIndex }"
    >
      <h-picture
        class="carousel__slide-image"
        :source="image.filename"
        ratio="org"
        :alt="image.alt"
        sizes="(min-width: 1000px) 50vw, (min-width: 600px) 50vw, calc(100vw)"
        :loading="index === 0 ? 'eager' : 'lazy'"
      />
    </div>
    <div class="carousel__pagination">
      <div
        class="carousel__pagination-item"
        v-for="(image, index) in images"
        :key="image.id"
        :class="{ '--active': activeIndex === index }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const speed = ref(5); // seconds
const activeIndex = ref(0);
let interval = null;

onMounted(() => {
  interval = setInterval(() => {
    activeIndex.value = (activeIndex.value + 1) % props.images.length;
  }, speed.value * 1000);
});
onBeforeUnmount(() => {
  clearInterval(interval);
});
</script>

<style lang="scss" scoped>
.carousel {
  --img-fade-speed: 1s;
  --speed: v-bind(speed);

  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity var(--img-fade-speed);
    transform: scale(1.2);

    opacity: 1;
    z-index: 2;

    // &.--active {
    //   z-index: 2;
    //   opacity: 1;
    //   transition: opacity var(--img-fade-speed);
    //   // animation: zoom-in calc(var(--speed) * 1s + 1) // var(--ease-out-quad);
    // }
    &.--inactive {
      opacity: 0;
      transition: opacity var(--img-fade-speed) 2s;
    }
  }

  &__slide-image {
    height: 100% !important;
    object-fit: cover;
  }

  &__pagination {
    position: absolute;
    left: 30px;
    bottom: 40px;
    z-index: 2;
    display: flex;
    gap: 7.5px;
  }

  &__pagination-item {
    position: relative;
    width: 16px;
    height: 2px;
    border-radius: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    overflow: hidden;
    transition: width var(--img-fade-speed) var(--ease-in-out-quart);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      transform: translateX(-100%);
    }

    &.--active {
      width: 35px;

      &::after {
        animation: grow calc(var(--speed) * 1s) var(--img-fade-speed) var(--ease-out-quad) forwards;
      }
    }
  }
}

@keyframes grow {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.2);
  }
}
</style>
