// Learn about IndexedDB: https://web.dev/indexeddb/#data-storage-limits
import { ref, watch } from "vue";
import { openDB } from "idb";

// db config
const dbName = "portfolio";
const dbVersion = 1;

// object store (table) config
const osCases = "cases";

// index config
const iLastVisited = "last_visited";
const iType = "type";

// upgrade
const upgrade = async (db, oldVersion, newVersion, transaction, event) => {
  switch (oldVersion) {
    case 0:
      // init setup
      const storeCases_0 = db.createObjectStore(osCases, { keyPath: "full_slug" });
      storeCases_0.createIndex(iLastVisited, "last_visited", { unique: false });
      storeCases_0.createIndex(iType, "type", { unique: false });
    // case 1:
    //   // add functionality for version 1 here...
  }
};

const db = ref(null);

export function useIndexedDb() {
  // reactive data entry with IndexedDB
  const dbEntry = async (storeName, key, options = {}) => {
    const { flush = "pre", deep = true, onError = (e) => console.error(e) } = options;

    const data = ref("");

    const read = async () => {
      console.log("READ (INDEXED DB)");
      try {
        // read existing value
        const value = await get(storeName, key);
        if (value) {
          data.value = value;
          return;
        }
        // create if not exists
        console.log("CREATE (INDEXED DB)");
        const keyPath = await getStoreKeyPath(storeName);
        const initValue = { [keyPath]: key };
        await set(storeName, initValue);
        data.value = initValue;
      } catch (e) {
        onError(e);
      }
    };
    const write = async () => {
      console.log("WRITE (INDEXED DB)");
      try {
        // remove if null
        if (data.value == null) {
          await del(storeName, key);
          return;
        }
        // add or update
        // IndexedDB does not support saving proxies, convert from proxy before saving
        if (Array.isArray(data.value) || typeof data.value === "object") {
          // array or object
          await update(storeName, key, JSON.parse(JSON.stringify(data.value)));
        } else {
          // primitive
          await update(storeName, key, data.value);
        }
      } catch (e) {
        onError(e);
      }
    };

    // get existing from local storage
    await read();

    // watch for changes and save to local storage
    watch(data, () => write(), { flush, deep });

    return data;
  };

  return {
    db,
    dbEntry,
    dbGetAll: getAll,
    dbGetAllByIndex: getAllByIndex,
  };
}

// HELPERS
const dbInit = async () => {
  return new Promise(async (resolve, reject) => {
    if (db.value) resolve();
    else {
      console.log("INIT (INDEXED DB)");
      try {
        // open db
        db.value = await openDB(dbName, dbVersion, { upgrade });
        resolve();
      } catch (error) {
        reject(e);
      }
    }
  });
};

const getStoreKeyPath = async (storeName) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      resolve(store.keyPath);
    } catch (error) {
      reject(error);
    }
  });
};

const getAll = async (storeName) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const value = await store.getAll();
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
};

const getAllByIndex = async (storeName, indexName, order = "asc", max = -1, skipFn = (i) => i) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const index = store.index(indexName);
      // loop through all items (item = cursor)
      let value = [];
      let cursor = await index.openCursor(null, order === "asc" ? "next" : "prev");
      while (cursor) {
        // console.log("\n\n", "👉 cursor -", cursor.key);

        // break if max reached
        if (max > -1 && value.length >= max) break;

        // dont add if skipFn returns true
        if (!skipFn(cursor.value)) {
          // add to array
          value.push(cursor.value);
        }
        cursor = await cursor.continue();
      }
      // console.log("\n\n", "👉 cursor - No more items in the store", "\n\n");
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
};

const get = async (storeName, key) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readonly");
      const store = tx.objectStore(storeName);
      const value = await store.get(key);
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
};

const set = async (storeName, value) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      await store.add(value);
      await tx.complete;
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
};

const del = async (storeName, key) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      await store.delete(key);
      await tx.complete;
      resolve(key);
    } catch (error) {
      reject(error);
    }
  });
};

const update = async (storeName, key, value) => {
  await dbInit();
  return new Promise(async (resolve, reject) => {
    try {
      const tx = db.value.transaction(storeName, "readwrite");
      const store = tx.objectStore(storeName);
      await store.put(value);
      await tx.complete;
      resolve(value);
    } catch (error) {
      reject(error);
    }
  });
};

// // use cursor to log all items
// try {
//   console.log("--- Use cursor to log all items");
//   const tx = db.value.transaction(osSearch, "readonly");
//   const objectStore = tx.objectStore(osSearch);
//   let cursor = await objectStore.openCursor();

//   // loop through all items
//   while (cursor) {
//     console.log("\n\n", "👉 cursor -", cursor.key);
//     console.table(cursor.value);

//     cursor = await cursor.continue();
//   }
//   console.log("\n\n", "👉 cursor - No more items in the store", "\n\n");
// } catch (error) {
//   console.log("operation failed", error);
// }
