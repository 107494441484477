<template>
  <div class="h-video-lazy" :class="{ '--cover': cover }">
    <video
      class="h-video-lazy__video"
      :autoplay="autoplay"
      :loop="loop"
      :muted="muted"
      :playsinline="playsinline"
      ref="videoEl"
    >
      <source :data-src="video?.filename" type="video/mp4" />
    </video>
    <h-picture
      v-if="poster?.filename && !videoLoaded"
      class="h-video-lazy__image"
      :source="poster?.filename"
      ratio="fill"
      :alt="poster?.title"
      sizes="(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)"
      loading="lazy"
    />
  </div>
</template>

<script setup>
import { onActivated, onMounted, ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

const props = defineProps({
  video: {
    type: Object,
    required: true,
  },
  poster: {
    type: Object,
    required: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  muted: {
    type: Boolean,
    default: true,
  },
  playsinline: {
    type: Boolean,
    default: true,
  },
  cover: {
    type: Boolean,
    default: false,
  },
});

const videoEl = ref(null);
const videoLoaded = ref(false);

onMounted(() => {
  // lazy load video when it comes into view
  const { stop } = useIntersectionObserver(
    videoEl,
    ([{ isIntersecting, target }]) => {
      if (isIntersecting) {
        for (var source in target.children) {
          var videoSource = target.children[source];
          if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
            videoSource.src = videoSource.dataset.src;
          }
        }
        target.load();
        videoLoaded.value = true;

        // stop observing
        stop();
      }
    },
    { rootMargin: "50%" }
  );
});

onActivated(() => {
  if (videoEl.value) {
    videoEl.value.play();
  }
});
</script>

<style lang="scss" scoped>
.h-video-lazy {
  &__video {
    .--cover & {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
