<template>
  <Head>
    <title>
      {{ meta.seo.title ? `${meta.seo.title} - ` : "" }}
      Isabella Capobianco
    </title>
    <meta name="description" :content="meta.seo.description" />
    <meta v-if="seoImage" property="og:image" :content="seoImage" />
  </Head>
</template>

<script setup>
import { computed } from "vue";
import { Head } from "@vueuse/head";
const props = defineProps({
  meta: {
    type: Object,
    default: () => ({
      seo: {},
      seoImage: {},
    }),
  },
});
const imgPath = computed(() => {
  if (props.meta.seoImage && props.meta.seoImage.filename) {
    return props.meta.seoImage.filename.replace("https://a.storyblok.com/", "");
  } else {
    return false;
  }
});
const imgService = "https://img2.storyblok.com/";
const imgOptions = "1200x627/smart/filters:format(jpg)/";

const seoImage = computed(() => {
  if (imgPath.value) {
    return imgService + imgOptions + imgPath.value;
  } else {
    return "fallback image";
  }
});
</script>

<style lang="scss" scoped></style>
