import { ref, onMounted } from "vue";

export function useIsTouchDevice() {
  const isTouchDevice = ref(false);

  onMounted(() => {
    isTouchDevice.value = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  });

  return isTouchDevice;
}
