<template>
  <footer class="footer" ref="footerRef">
    <l-section :bg-color="'bg-tertiary'" noBorder data-comp="g-footer">
      <l-container>
        <l-grid>
          <l-grid-item span-desktop="6" span-tablet="7">
            <l-flex gap="xlarge">
              <l-flex gap="xsmall">
                <a-header tag="h2" classes="h3" class="footer__title">
                  <a-header-display
                    :text="footer.title"
                    :positions="['top', 'bottom']"
                    :outlines="[false, true]"
                    color="cream"
                  />
                </a-header>
                <sb-richtext :text="footer.description" />
              </l-flex>
              <l-flex gap="xsmall" align-items="flex-start" class="footer__contact">
                <a-header tag="h3" classes="h5">
                  <span>Get in touch</span>
                </a-header>
                <h-slug :to="{ email: shared.email }" class="link-u">{{ shared.email }}</h-slug>
                <h-slug :to="{ url: `tel:${shared.phone}` }" class="link-u">{{ shared.phone }}</h-slug>
              </l-flex>
              <l-flex gap="xsmall" align-items="flex-start" class="footer__contact">
                <a-header tag="h3" classes="h5">
                  <span>Find me</span>
                </a-header>
                <h-slug
                  v-for="soMe in shared.socialMedia"
                  :key="soMe._uid"
                  :to="soMe.link"
                  target="_blank"
                  class="link-u"
                  v-editable="soMe"
                >
                  {{ soMe.label }}
                </h-slug>
              </l-flex>
            </l-flex>
          </l-grid-item>
          <l-grid-item span-desktop="6" span-tablet="5" align-x="center" align-y="center">
            <div class="footer__image-wrap">
              <h-picture
                v-if="footer.image && footer.image.filename"
                class="footer__image"
                :source="footer.image.filename"
                ratio="4/5"
                :alt="footer.title"
                sizes="(min-width: 1000px) 100vw, (min-width: 600px) 100vw, calc(100vw)"
                loading="lazy"
              />
            </div>
          </l-grid-item>
        </l-grid>
      </l-container>
    </l-section>
  </footer>
  <!-- <pre lang="json">{{ { footer } }}</pre> -->
</template>

<script setup>
import { ref } from "vue";
import { useGlobals } from "@/composables/useGlobals.js";
import { useIntersectionObserver } from "@vueuse/core";
import { intersectionThresholds } from "@/assets/scripts/intersection-helpers.js";
import { useLogoAnimation } from "@/composables/useLogoAnimation.js";

const { footer, shared } = useGlobals();
const { footerVisible } = useLogoAnimation();
const footerRef = ref(null);

useIntersectionObserver(
  footerRef,
  ([{ boundingClientRect }], observerElement) => {
    if (boundingClientRect.top < 400) {
      footerVisible.value = true;
    } else {
      footerVisible.value = false;
    }
  },
  { threshold: intersectionThresholds() }
);
</script>

<style lang="scss">
.footer {
  position: relative;
  z-index: 1;
  &__title {
    margin-bottom: var(--flex-gap-small);
  }
  &__image-wrap {
    max-height: 50vh;
    // @include breakpoint(mobile-landscape, min) {
    //   width: 75%;
    // }
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
    height: 100% !important;
    object-fit: contain;
  }

  &__contact {
    gap: 0.5rem;
  }
}
</style>
