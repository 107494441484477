<template>
  <svg
    width="77"
    height="69"
    viewBox="0 0 77 69"
    fill="none"
    data-comp="i-quote"
  >
    <rect
      x="44.6794"
      y="1.48209"
      width="31.0936"
      height="31.0936"
      stroke="#0038B8"
      stroke-width="1.82745"
    />
    <path
      d="M75.7461 32.5508L75.7039 35.7516C75.474 53.2267 61.2423 67.2724 43.7656 67.2724V67.2724"
      stroke="#0038B8"
      stroke-width="1.82745"
    />
    <path
      d="M70 32.5684L69.9682 34.9564C69.772 49.7107 57.7556 61.5684 43 61.5684V61.5684"
      stroke="#0038B8"
      stroke-width="1.82745"
    />
    <path
      d="M43 49.5684L60 49.5684V61.0684L43 64.0684V49.5684Z"
      fill="#F6FAFF"
    />
    <rect
      x="1.67935"
      y="1.48209"
      width="31.0936"
      height="31.0936"
      stroke="#0038B8"
      stroke-width="1.82745"
    />
    <path
      d="M32.7461 32.5508L32.7039 35.7516C32.474 53.2267 18.2423 67.2724 0.765625 67.2724V67.2724"
      stroke="#0038B8"
      stroke-width="1.82745"
    />
    <path
      d="M27 32.5684L26.9682 34.9564C26.772 49.7107 14.7556 61.5684 0 61.5684V61.5684"
      stroke="#0038B8"
      stroke-width="1.82745"
    />
    <path d="M0 49.5684L17 49.5684V61.0684L0 64.0684V49.5684Z" fill="#F6FAFF" />
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
