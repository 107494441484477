export default {
  colorWhite: '#ffffff',
  colorDark: '#1a1a1a',
  colorCream: '#fffefb',
  colorGreen: '#33635c',
  colorBlue: '#77a1aa',
  colorRed: '#a5656c',
  colorYellow: '#fff8bd',
  colorPurple: '#7b61ff',
}
