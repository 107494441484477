<template>
  <div data-comp="a-page-intro-animation">
    <div class="page-intro-logo" v-if="firstLoad">
      <svg fill="none" viewBox="0 0 177 86" class="page-intro-logo__animation">
        <path d="M1.237 84.794V1.536l86.372 83.258 88.448-83.258v83.258" />
        <path d="M1.237 84.794V1.536l174.82 83.258V1.536" />
        <path d="m1.237 1.536 86.372 83.258 88.448-83.258" />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { useGlobals } from "@/composables/useGlobals.js";
const { firstLoad } = useGlobals();
</script>

<style lang="scss" scoped>
// remember to sync the time in @/01-atoms/hero-lines.vue
$line-logo-animation-time: 0.6;

.page-intro-logo {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-secondary);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: ani-out 2s #{$line-logo-animation-time * 3}s linear forwards;

  &__animation {
    width: 20vw;
    min-width: 200px;

    & path {
      opacity: 0.7;
      stroke: #ff7e76;
      stroke-dasharray: 520;
      stroke-dashoffset: 520;

      &:nth-child(1) {
        .loaded & {
          animation: dash-in #{$line-logo-animation-time}s #{$line-logo-animation-time}s
              linear forwards,
            dash-out #{$line-logo-animation-time * 0.5}s #{$line-logo-animation-time *
              4}s linear forwards;
        }
      }

      &:nth-child(2) {
        .loaded & {
          animation: dash-in #{$line-logo-animation-time}s #{$line-logo-animation-time *
              2}s linear forwards,
            dash-out #{$line-logo-animation-time * 0.5}s #{$line-logo-animation-time *
              4}s linear forwards;
        }
      }

      &:nth-child(3) {
        .loaded & {
          animation: dash-last #{$line-logo-animation-time}s #{$line-logo-animation-time *
              3}s linear forwards,
            dash-out #{$line-logo-animation-time * 0.5}s #{$line-logo-animation-time *
              4}s linear forwards;
        }
      }
    }
  }
}

@keyframes dash-in {
  90% {
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 0;
    opacity: 0.7;
  }
}

@keyframes dash-last {
  30% {
    opacity: 1;
  }

  90% {
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

@keyframes dash-out {
  30% {
    opacity: 1;
  }

  90% {
    stroke-dashoffset: -520;
    opacity: 0.3;
  }

  100% {
    stroke-dashoffset: -520;
    opacity: 0;
  }
}

@keyframes ani-out {
  0% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
